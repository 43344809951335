import { useContext } from 'react';
import { AnalyticsContext, AnalyticsContextProps } from '~/providers';

export const useAnalytics = (): AnalyticsContextProps => {
    const context = useContext(AnalyticsContext);
    if (!context) {
        throw new Error('useAnalytics must be used within an AnalyticsProvider');
    }
    return context;
};
