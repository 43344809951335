import { styled } from '@slal/ui';
import { breakpointUp } from '@slal/ui/helpers';
import { Col, Container } from '@slal/ui/layout';

export const StyledContainer = styled(Container)`
    padding-top: ${({ theme }) => theme.space[4]};
    padding-bottom: ${({ theme }) => theme.space[4]};
`;

export const StyledSidebarCol = styled(Col)`
    padding-top: ${({ theme }) => theme.space[7]};

    ${breakpointUp.md`
        padding-top: ${({ theme }) => theme.space[0]};    
    `}
`;
