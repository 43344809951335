import { Suspense, lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Layout, PageLoader } from '~/components';

const LandingRoute = lazy(() => import('./LandingRoute'));

export const AppRoutes = () => (
    <Suspense fallback={<PageLoader />}>
        <Routes>
            <Route
                element={
                    <Layout>
                        <Outlet />
                    </Layout>
                }
            >
                <Route path="/" element={<LandingRoute />} />
            </Route>
        </Routes>
    </Suspense>
);
