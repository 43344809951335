import { Suspense, lazy } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { Layout, PageLoader } from '~/components';
import { ApplicationContextProvider } from '~/features';
import { ApplicationFormKeys } from '~/types';

const LandingRoute = lazy(() => import('./LandingRoute'));
const ConsiderationsRoute = lazy(() => import('./ConsiderationsRoute'));
const ApplicationGuidanceRoute = lazy(() => import('./ApplicationGuidanceRoute'));
const ApplicationStartRoute = lazy(() => import('./ApplicationStartRoute'));
const ApplicationDataConsentRoute = lazy(() => import('./ApplicationDataConsentRoute'));
const ApplicationAddPersonalInformationRoute = lazy(() => import('./ApplicationAddPersonalInformationRoute'));
const ApplicationAddAdditionalDetailsRoute = lazy(() => import('./ApplicationAddAdditionalDetailsRoute'));
const ApplicationAddPensionRoute = lazy(() => import('./ApplicationAddPensionRoute'));
const ApplicationWithdrawalOptionsRoute = lazy(() => import('./ApplicationWithdrawalOptionsRoute'));
const ApplicationLumpSumOptionsRoute = lazy(() => import('./ApplicationLumpSumOptionsRoute'));
const ApplicationRegularIncomeRoute = lazy(() => import('./ApplicationRegularIncomeRoute'));
const ApplicationSavingsInvesmentOptionsRoute = lazy(() => import('./ApplicationSavingsInvesmentOptionsRoute'));
const ApplicationDrawdownInvesmentOptionsRoute = lazy(() => import('./ApplicationDrawdownInvesmentOptionsRoute'));
const ApplicationPotReviewRoute = lazy(() => import('./ApplicationPotReviewRoute'));
const ApplicationFundOptionsRoute = lazy(() => import('./ApplicationFundOptionsRoute'));
const ApplicationDrawdownInvestmentPathwayOptionsRoute = lazy(
    () => import('./ApplicationDrawdownInvestmentPathwayOptionsRoute')
);
const ApplicationReviewAndConfirmRoute = lazy(() => import('./ApplicationReviewAndConfirmRoute'));
const ApplicationKeyConsiderationsHealthIssuesRoute = lazy(
    () => import('./ApplicationKeyConsiderationsHealthIssuesRoute')
);
const ApplicationKeyConsiderationsInvestmentScamsRoute = lazy(
    () => import('./ApplicationKeyConsiderationsInvestmentScamsRoute')
);
const ApplicationKeyConsiderationsOutstandingDebtsRoute = lazy(
    () => import('./ApplicationKeyConsiderationsOutstandingDebtsRoute')
);
const ApplicationKeyConsiderationsMeansTestedBenefitsRoute = lazy(
    () => import('./ApplicationKeyConsiderationsMeansTestedBenefitsRoute')
);
const ApplicationKeyConsiderationsContinueContributingRoute = lazy(
    () => import('./ApplicationKeyConsiderationsContinueContributingRoute')
);
const ApplicationKeyConsiderationsStillInvestedRoute = lazy(
    () => import('./ApplicationKeyConsiderationsStillInvestedRoute')
);
const ApplicationKeyConsiderationsRemainingPensionRoute = lazy(
    () => import('./ApplicationKeyConsiderationsRemainingPensionRoute')
);
const ApplicationKeyConsiderationsInformationRoute = lazy(
    () => import('./ApplicationKeyConsiderationsInformationRoute')
);
const ApplicationKeyConsiderationsMainPlanRoute = lazy(() => import('./ApplicationKeyConsiderationsMainPlanRoute'));
const ApplicationBankDetailsRoute = lazy(() => import('./ApplicationBankDetailsRoute'));
const ApplicationReviewDefaultSavingsFundRoute = lazy(() => import('./ApplicationReviewDefaultSavingsFundRoute'));
const ApplicationReviewPersonalInformationRoute = lazy(() => import('./ApplicationReviewPersonalInformationRoute'));
const ApplicationReviewPensionRoute = lazy(() => import('./ApplicationReviewPensionRoute'));
const ApplicationConfirmationRoute = lazy(() => import('./ApplicationConfirmationRoute'));

export const AppRoutes = () => (
    <Suspense fallback={<PageLoader />}>
        <Routes>
            <Route
                element={
                    <Layout>
                        <Outlet />
                    </Layout>
                }
            >
                <Route path="/" element={<LandingRoute />} />
                <Route path="/considerations" element={<ConsiderationsRoute />} />
                <Route
                    path="/application"
                    element={
                        <ApplicationContextProvider>
                            <Outlet />
                        </ApplicationContextProvider>
                    }
                >
                    <Route path="guidance" element={<ApplicationGuidanceRoute />} />
                    <Route path="start" element={<ApplicationStartRoute />} />
                    <Route path="data-consent" element={<ApplicationDataConsentRoute />} />
                    <Route path="add-personal-information" element={<ApplicationAddPersonalInformationRoute />} />
                    <Route path="review-personal-information" element={<ApplicationReviewPersonalInformationRoute />} />
                    <Route path="add-additional-details" element={<ApplicationAddAdditionalDetailsRoute />} />
                    <Route path="add-pension" element={<ApplicationAddPensionRoute />} />
                    <Route path="review-pension" element={<ApplicationReviewPensionRoute />} />

                    <Route path="withdrawal-options" element={<ApplicationWithdrawalOptionsRoute />} />
                    <Route path="lump-sum-options" element={<ApplicationLumpSumOptionsRoute />} />
                    <Route path="regular-income" element={<ApplicationRegularIncomeRoute />} />
                    <Route path="pot-review" element={<ApplicationPotReviewRoute />} />
                    <Route path="savings">
                        <Route path="investment-options" element={<ApplicationSavingsInvesmentOptionsRoute />} />
                        <Route
                            path="fund-options"
                            element={
                                <ApplicationFundOptionsRoute formKey={ApplicationFormKeys.SAVINGS_FUND_ALLOCATIONS} />
                            }
                        />
                        <Route path="default" element={<ApplicationReviewDefaultSavingsFundRoute />} />
                    </Route>
                    <Route path="drawdown">
                        <Route path="investment-options" element={<ApplicationDrawdownInvesmentOptionsRoute />} />
                        <Route
                            path="fund-options"
                            element={
                                <ApplicationFundOptionsRoute formKey={ApplicationFormKeys.DRAWDOWN_FUND_ALLOCATIONS} />
                            }
                        />
                        <Route
                            path="investment-pathway-options"
                            element={<ApplicationDrawdownInvestmentPathwayOptionsRoute />}
                        />
                    </Route>
                    <Route path="key-considerations">
                        <Route path="information" element={<ApplicationKeyConsiderationsInformationRoute />} />
                        <Route path="main-plan" element={<ApplicationKeyConsiderationsMainPlanRoute />} />
                        <Route path="health-issues" element={<ApplicationKeyConsiderationsHealthIssuesRoute />} />
                        <Route path="investment-scams" element={<ApplicationKeyConsiderationsInvestmentScamsRoute />} />
                        <Route
                            path="outstanding-debts"
                            element={<ApplicationKeyConsiderationsOutstandingDebtsRoute />}
                        />
                        <Route
                            path="means-tested-benefits"
                            element={<ApplicationKeyConsiderationsMeansTestedBenefitsRoute />}
                        />
                        <Route
                            path="continue-contributing"
                            element={<ApplicationKeyConsiderationsContinueContributingRoute />}
                        />
                        <Route path="still-invested" element={<ApplicationKeyConsiderationsStillInvestedRoute />} />
                        <Route
                            path="remaining-pension"
                            element={<ApplicationKeyConsiderationsRemainingPensionRoute />}
                        />
                    </Route>
                    <Route path="bank-details" element={<ApplicationBankDetailsRoute />} />
                    <Route path="review-and-confirm" element={<ApplicationReviewAndConfirmRoute />} />
                    <Route path="confirmation" element={<ApplicationConfirmationRoute />} />
                </Route>
            </Route>
        </Routes>
    </Suspense>
);
