import { styled } from '@slal/ui';
import { Card } from '~/components';

export const StyledLoadingSection = styled.section`
    padding: ${({ theme }) => theme.colors.neutral__light__30};
`;

export const LoadingCard = styled(Card)`
    border-color: ${({ theme }) => theme.colors.neutral__light__30};
`;
