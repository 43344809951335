import { createContext, useContext } from 'react';
import { ApplicationContextProps } from '~/types';

export const ApplicationContext = createContext<ApplicationContextProps | undefined>(undefined);

export const useApplicationContext = (): ApplicationContextProps => {
    const context = useContext(ApplicationContext);
    if (!context) {
        throw new Error('useApplicationContext must be used within an ApplicationContextProvider');
    }
    return context;
};
