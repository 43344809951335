import { Heading } from '@slal/ui/typography';

import { StyledContent } from './styles';
import { ContentProps } from './types';

export const Content = ({ children, title, ...props }: ContentProps) => {
    const id = 'main-content-title';

    return (
        <StyledContent role="main" aria-labelledby={id} {...props}>
            {title && (
                <Heading id={id} level={1}>
                    {title}
                </Heading>
            )}
            {children}
        </StyledContent>
    );
};
