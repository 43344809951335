import { StyledContainer, StyledHeader, StyledLogo } from './styles';

export const Header = () => {
    return (
        <StyledHeader>
            <StyledContainer>
                <StyledLogo variant="dark" />
            </StyledContainer>
        </StyledHeader>
    );
};
