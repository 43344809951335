export const roundCurrency = (value: number, multiplier: number = 100) =>
    Math.floor((value + Number.EPSILON) * multiplier) / multiplier;

export const formatToGBP = (value: string | number | null) => {
    if (!value) return '';

    let numericValue = value;

    if (typeof value === 'string') {
        numericValue = parseFloat(value);
        if (isNaN(numericValue)) return '';
    }

    numericValue = roundCurrency(numericValue as number, 1);

    const formattedValue = numericValue.toLocaleString('en-GB', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    return formattedValue;
};
