import { styled } from '@slal/ui';
import { LoadingDots } from '@slal/ui/animations';
import { breakpointUp } from '@slal/ui/helpers';
import { Heading } from '@slal/ui/typography';

export const StyledHeading = styled(Heading)`
    color: ${({ theme }) => theme.colors.neutral__light__00};
    font-size: ${({ theme }) => theme.fontSizes[11]};
`;

export const StyledContent = styled.div`
    h1,
    h2 {
        margin-bottom: ${({ theme }) => theme.space[6]};
    }
    ${StyledHeading} {
        margin-bottom: ${({ theme }) => theme.space[0]};
    }
`;

export const StyledPageLoadingDots = styled(LoadingDots)`
    display: flex;
    height: 50vh;
    align-items: center;
    justify-content: center;
`;

export const DisplayMobile = styled.div`
    display: block;
    ${breakpointUp.md`
        display:none;    
    `}
`;

export const DisplayDesktop = styled.div`
    display: none;
    ${breakpointUp.md`
        display:block;    
    `}
`;

export const MaxWidthContent = styled.section`
    ${breakpointUp.lg`
        max-width: 512px;
    `}
    ${breakpointUp.xl`
        max-width: 660px;
    `}
`;

export const StyledApplicationButtonsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;

    button {
        width: 100%;
        margin-top: ${({ theme }) => theme.space[5]};
        &:first-child {
            margin-top: 0;
        }
    }

    ${breakpointUp.sm`    
        gap: ${({ theme }) => theme.space[4]};
        button {
            width: auto;
            margin-top: 0;
        }
    `}
`;
