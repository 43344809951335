import { isEmpty } from 'lodash';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApplicationContext, useApplication } from '~/hooks';
import {
    ApplicationContextProps,
    ApplicationFormKeys,
    ApplicationPensionValuesInput,
    ApplicationSchema,
} from '~/types';
import { getPensionValues } from '~/utils';

const getPensionValuesInput = (application: Partial<ApplicationSchema>): ApplicationPensionValuesInput => {
    const { regularIncome, ...otherProps } = application;

    if (!regularIncome || isEmpty(regularIncome)) return otherProps;
    return {
        ...otherProps,
        regularIncome:
            !regularIncome || isEmpty(regularIncome)
                ? undefined
                : (regularIncome as ApplicationPensionValuesInput['regularIncome']),
    };
};

const getValues = (getSession: (formKey: ApplicationFormKeys) => ApplicationSchema | undefined) => {
    const withdrawalOptions = getSession(ApplicationFormKeys.WITHDRAWAL_OPTIONS);
    const pensionTransfer = getSession(ApplicationFormKeys.PENSION_TRANSFER);
    const lumpSumOptions = getSession(ApplicationFormKeys.LUMP_SUM_OPTIONS);
    const regularIncome = getSession(ApplicationFormKeys.REGULAR_INCOME);
    const savingsInvestmentOption = getSession(ApplicationFormKeys.SAVINGS_INVESTMENT_OPTIONS);
    const drawdownInvestmentOption = getSession(ApplicationFormKeys.DRAWDOWN_INVESTMENT_OPTIONS);
    const schemeDetails = getSession(ApplicationFormKeys.SCHEME);

    const pensionValuesInput = getPensionValuesInput({
        ...lumpSumOptions,
        ...pensionTransfer,
        ...regularIncome,
        ...withdrawalOptions,
    });

    const pensionValues = getPensionValues(pensionValuesInput);

    return {
        investmentOptions: {
            drawdown: drawdownInvestmentOption?.drawdownInvestment?.option,
            savings: savingsInvestmentOption?.savingsInvestment?.option,
        },
        pensionValues,
        schemeDataConsent: !!schemeDetails?.scheme.dataUsagePreference,
        schemeId: schemeDetails?.scheme.id,
        schemeDiscount: schemeDetails?.scheme?.discount,
        withdrawalOption: withdrawalOptions?.withdrawal?.option,
    };
};

export const ApplicationContextProvider = ({ children }: PropsWithChildren) => {
    const { getSession } = useApplication();
    const { pathname } = useLocation();
    const [application, setApplication] = useState<ApplicationContextProps>(getValues(getSession));

    useEffect(() => {
        setApplication(getValues(getSession));
    }, [getSession, pathname, setApplication]);

    return <ApplicationContext.Provider value={application}>{children}</ApplicationContext.Provider>;
};
