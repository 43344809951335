import { styled } from '@slal/ui';
import { ChevronLeftIcon, ChevronRightIcon } from '@slal/ui/icons';

export const StyledNav = styled.nav``;

export const StyledList = styled.ul``;

export const StyledListItem = styled.li`
    display: inline-flex;
    list-style: none;
    margin: 0 ${({ theme }) => theme.space[4]};
    padding: 0;

    &:last-child {
        margin: 0;
    }
`;

const paginationButtonCss = `
    cursor: pointer;
    user-select: none;
`;

export const StyledNextPageButton = styled(ChevronRightIcon)`
    ${paginationButtonCss}
`;

StyledNextPageButton.defaultProps = {
    role: 'button',
};

export const StyledPreviousPageButton = styled(ChevronLeftIcon)`
    ${paginationButtonCss}
`;

StyledPreviousPageButton.defaultProps = {
    role: 'button',
};
