import { useContext, useLayoutEffect, useState } from 'react';
import { SL_COOKIE_NAME } from '~/hooks';
import { AnalyticsContext } from '~/providers';

declare const window: Window;

const setCookie = (cname: string, cvalue: string, exdays: number, domain: string) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    document.cookie = `${cname}=${cvalue}; ${expires}; path=/; domain=${domain}`;
};

export const useCookieBanner = () => {
    const analyticsContext = useContext(AnalyticsContext);
    const [visible, setVisible] = useState(false);
    const domain = window.location.host.includes('localhost') ? 'localhost' : '.standardlife.co.uk';

    useLayoutEffect(() => {
        if (
            !analyticsContext?.standardLifeCookie ||
            !JSON.parse(analyticsContext.standardLifeCookie as string).cookiecategoryids
        ) {
            setVisible(true);
        }
    }, [analyticsContext?.standardLifeCookie]);

    const handleDeclineAll = () => {
        setCookie(SL_COOKIE_NAME, '{"cookiecategoryids":"1", "version":"7"}', 180, domain);
        setVisible(false);
    };

    const handleAcceptAll = () => {
        setCookie(SL_COOKIE_NAME, '{"cookiecategoryids":"1|2|3", "version":"7"}', 180, domain);
        setVisible(false);
    };

    return {
        handleDeclineAll,
        handleAcceptAll,
        setVisible,
        visible,
    };
};
