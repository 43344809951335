import { styled } from '@slal/ui';
import { StandardLifeLogo } from '@slal/ui/brand';
import { Container } from '@slal/ui/layout';

export const StyledHeader = styled.header`
    background: ${({ theme }) => theme.colors.neutral__light__00};
`;

export const StyledContainer = styled(Container)`
    display: flex;
    gap: ${({ theme }) => theme.space[6]};
    align-items: center;
    padding: ${({ theme }) => theme.space[5]};
`;

export const StyledLogo = styled(StandardLifeLogo)`
    width: 170px;
    height: auto;
    margin-bottom: ${({ theme }) => theme.space[4]};
`;
