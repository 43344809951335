import { ReactNode, createContext, useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import { useCookieValues } from '~/hooks';
import { StandardLifeCookie } from '~/types';

export interface AnalyticsContextProps {
    pushEvent: (data: Record<string, string>) => void;
    standardLifeCookie: StandardLifeCookie;
}

export enum TaggingSource {
    DASHBOARD = 'dashboard',
    MICROSITE = 'microsite',
}

const TAGGING_SOURCE_KEY = 'TAGGING_SOURCE';

export const AnalyticsContext = createContext<AnalyticsContextProps | undefined>(undefined);

export const eventName = 'ROMT';
export const eventCategory = 'ROMT application';

export const AnalyticsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { insights, marketing, standardLifeCookie } = useCookieValues();
    const [insightsInitialised, setInsightsInitialised] = useState<boolean>(false);
    const [marketingInitialised, setMarketingInitialised] = useState<boolean>(false);
    const { pathname } = useLocation();
    let source = sessionStorage.getItem(TAGGING_SOURCE_KEY);
    if (!source) {
        source = pathname.includes('/scheme/') ? TaggingSource.MICROSITE : TaggingSource.DASHBOARD;
        sessionStorage.setItem(TAGGING_SOURCE_KEY, source);
    }

    useEffect(() => {
        if (!marketingInitialised && marketing) {
            TagManager.initialize({
                gtmId: import.meta.env.VITE_MARKETING_GTM_CODE,
            });
            setMarketingInitialised(true);
            return;
        } else if (marketingInitialised && !marketing) {
            setMarketingInitialised(false);
        }
    }, [marketingInitialised, setMarketingInitialised]);

    useEffect(() => {
        if (!insightsInitialised && insights) {
            TagManager.initialize({
                gtmId: import.meta.env.VITE_EXPERIENCE_AND_INSIGHTS_GTM_CODE,
                events: {
                    [eventName]: eventName,
                },
            });
            setInsightsInitialised(true);
            return;
        } else if (insightsInitialised && !insights) {
            setInsightsInitialised(false);
        }
    }, [insightsInitialised, setInsightsInitialised]);

    const pushEvent = useCallback(
        (data: Record<string, string>) => {
            if (insightsInitialised) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: eventName,
                        eventAction: pathname,
                        eventCategory,
                        source,
                        ...data,
                    },
                });
            }
        },
        [insightsInitialised, pathname, source]
    );

    useEffect(() => {
        pushEvent({ eventLabel: 'Page load' });
    }, [insightsInitialised, pathname]);

    return <AnalyticsContext.Provider value={{ pushEvent, standardLifeCookie }}>{children}</AnalyticsContext.Provider>;
};
