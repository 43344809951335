import { Skeleton } from '@slal/ui/animations';
import { Article, P } from '@slal/ui/typography';
import { PropsWithChildren } from 'react';
import { MaxWidthContent } from '~/components';
import { useUserContext } from '~/hooks';
import { ApplicationFormLayout } from '~/layouts';
import { LoadingCard, StyledLoadingSection } from '../routes/styles';

const LoadingSkeleton = () => {
    return (
        <ApplicationFormLayout>
            <MaxWidthContent>
                <StyledLoadingSection>
                    <LoadingCard aria-label="Loading...">
                        <Article>
                            <P>
                                <Skeleton width="20%" />
                            </P>
                            <P>
                                <Skeleton width="70%" />
                            </P>
                            <P>
                                <Skeleton width="40%" />
                            </P>
                            <P>
                                <Skeleton width="75%" />
                            </P>
                            <P>
                                <Skeleton width="50%" />
                            </P>
                            <P>
                                <Skeleton width="73%" />
                            </P>
                        </Article>
                    </LoadingCard>
                </StyledLoadingSection>
            </MaxWidthContent>
        </ApplicationFormLayout>
    );
};
export const UserContextLoadingWrapper = ({ children }: PropsWithChildren) => {
    const { loading } = useUserContext();

    if (loading) return <LoadingSkeleton />;

    return children;
};
