import { useContext } from 'react';
import { UserContext, UseUserContextResponse } from './types';

export const useUserContext = (): UseUserContextResponse => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUserContext must be used within an UserContextProvider');
    }
    return context;
};
